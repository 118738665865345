import React, { useEffect } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import PriceList from '../../interfaces/output/priceList';
import { useDispatch, useSelector } from 'react-redux';
import { createPriceListVersion, fetchPriceListById, getPriceList, isCreatingPricelistVersion, isLoadingPriceList } from '../../reducers/priceListReducer';
import { fetchPriceListVersionById, isUpdatingPriceListStatus, isUpdatingVersion, updatePriceListVersion,
    updatePriceListVersionStatus } from '../../reducers/priceListVersionReducer';
import WorkflowUtils from '../../utils/workflowUtils';
import { isCloningVersion } from '../../reducers/priceListVersionReducer';
import PriceListVersion from '../../interfaces/output/priceListVersions';
import GenericStepper from '../common/widgets/genericStepper';
import UrlConstants from '../../constants/UrlConstants';
import AddPriceListVersionStep1 from './pricelistVersion/addPriceListVersionStep1';
import GenericStep from '../../interfaces/common/genericStep';
import AddPriceListVersionStep3 from './pricelistVersion/addPriceListVersionStep3';
import { FormType } from '../../constants/constants';
import AddPriceListVersionStep4 from './pricelistVersion/addPriceListVersionStep4';
import { ensure } from '../../utils/arrayUtils';
import AddPriceListVersionStep5 from './pricelistVersion/addPriceListVersionStep5';
import AddPriceListVersionStep2 from './pricelistVersion/addPriceListVersionStep2';
import { PriceListVersionOptions } from '../../constants/priceListVersionOptions';
import PricelistVersionStep1Validation from './validation/pricelistVersionStep1Validation';
import PricelistVersionStep2Validation from './validation/pricelistVersionStep2Validation';
import StepperAdditionalInformation from '../common/widgets/stepperAdditionalInformation';

interface IAddPriceListVersionProps {
    priceListId?: number;
    priceListVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit price list version'),
    add: LanguageUtils.createMessage('Add price list version'),
    changeStatus: LanguageUtils.createMessage('Change version status'),
    clone: LanguageUtils.createMessage('CLONE_CURRENT_VERSION')
};

export default function AddPriceListVersionForm(props: IAddPriceListVersionProps): JSX.Element {
    const { priceListId, priceListVersionId, steps, type } = props;
    const [value, setValue] = React.useState('');
    const [priceList, setPriceList] = React.useState<PriceList>({ id: priceListId } as PriceList);
    const [selectedPriceListVersionId, setSelectedPriceListVersionId] = React.useState<number>();
    const dispatch = useDispatch();
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsPriceList = useSelector(getPriceList);
    const propsIsUpdatingPriceListVersion = useSelector(isUpdatingVersion);
    const propsIsCreatingPriceListVersion = useSelector(isCreatingPricelistVersion);
    const prevIsCreatingPriceListVersion = WorkflowUtils.usePrevious<boolean>(propsIsCreatingPriceListVersion);
    const propsIsLoadingPriceList = useSelector(isLoadingPriceList);
    const prevIsLoadingPriceList = WorkflowUtils.usePrevious<boolean>(propsIsLoadingPriceList);
    const prevIsUpdatingPriceListVersion = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingPriceListVersion);
    const propsIsCloningVersion = useSelector(isCloningVersion);
    const prevIsCloningVersion = WorkflowUtils.usePrevious<boolean>(propsIsCloningVersion);
    const propsIsUpdatingPriceListStatus = useSelector(isUpdatingPriceListStatus);
    const prevIsUpdatingPriceListStatus = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingPriceListStatus);
    
    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if(prevIsUpdatingPriceListVersion === true && !propsIsUpdatingPriceListVersion || prevIsCreatingPriceListVersion === true && !propsIsCreatingPriceListVersion) {
                    setRedirectCondition(true);
                }
                break;
        
            case 3:
                if(prevIsCloningVersion === true && !propsIsCloningVersion) {
                    setRedirectCondition(true);
                }
                break;
        
            case 4:
                if(prevIsUpdatingPriceListStatus === true && !propsIsUpdatingPriceListStatus) {
                    setRedirectCondition(true);
                }
                break;
            case 5:
                if(prevIsCreatingPriceListVersion === true && !propsIsCreatingPriceListVersion) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsUpdatingPriceListVersion, propsIsCloningVersion, propsIsUpdatingPriceListStatus, propsIsCreatingPriceListVersion]);

    useEffect(() => {
        if(priceListId) {
            dispatch(fetchPriceListById({ id: priceListId,
                priceListVersionId }));
        }
    }, [dispatch]);

    useEffect(() => {
        if(priceListVersionId) {
            dispatch(fetchPriceListVersionById(priceListVersionId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(!propsIsLoadingPriceList && prevIsLoadingPriceList) {
            setPriceList(propsPriceList);
        }

    }, [propsIsLoadingPriceList]);

    const onChange = (attribute: string, value: any) => {
        const newPricelistVersion = { ...priceList.version } as PriceListVersion;
        (newPricelistVersion as any)[attribute] = value;

        setPriceList({
            ...priceList,
            version: newPricelistVersion });
    };

    const onPriceListVersionOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        setSelectedPriceListVersionId(undefined);
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddPriceListVersionStep1 onChange={onChange} priceList={priceList} />,
            validationFn: () => PricelistVersionStep1Validation.validatePricelistForm(priceList),
            excludeIf: value !== PriceListVersionOptions.BlankVersion && !priceListVersionId,
            onNext: () => {
                if(!priceListVersionId) {
                    dispatch(createPriceListVersion(priceList));
                }
                else {
                    dispatch(updatePriceListVersion(ensure(priceList.version)));
                }
            }
        } as GenericStep,
        {
            id: 2,
            content: <AddPriceListVersionStep2 value={value} onChange={onPriceListVersionOptionChange} />,
            validationFn: () => PricelistVersionStep2Validation.validatePricelistForm(value, priceList.currentVersion?.id)
        } as GenericStep,
        {
            id: 3,
            name: 'clone',
            content: <AddPriceListVersionStep3 priceListId={priceList.id} selectedPriceListVersionId={selectedPriceListVersionId}
                onChange={setSelectedPriceListVersionId}/>,
            excludeIf: value !== PriceListVersionOptions.InheritFromAnother,
            disabled: !selectedPriceListVersionId,
            xl: true
        } as GenericStep,
        {
            id: 4,
            content: <AddPriceListVersionStep4 onChange={onChange} priceList={priceList} />,
            onNext: () => dispatch(updatePriceListVersionStatus(ensure(priceList.version)))
            
        } as GenericStep,
        {
            id: 5,
            name: 'create',
            content: <AddPriceListVersionStep5 onChange={onChange} priceList={priceList} />,
            excludeIf: value ? (value !== PriceListVersionOptions.InheritFromActive && value !== PriceListVersionOptions.InheritFromAnother) : false,
            onNext: () => {
                dispatch(createPriceListVersion({ version: {
                    adjustWithPercentage: priceList.version?.adjustWithPercentage,
                    copyFromVersionId: value === PriceListVersionOptions.InheritFromActive ? priceList.currentVersion?.id : selectedPriceListVersionId || priceList.version?.id,
                    validFromDate: priceList.version?.validFromDate,
                    validToDate: priceList.version?.validToDate
                } ,
                id: priceList.id }));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            case FormType.ChangeStatus:
                return messages.changeStatus;
            case FormType.Clone:
                return messages.clone;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = steps.map(step => ensure(getSteps().find(getStep => getStep.id === step)));

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={`/${UrlConstants.PRICE_LISTS}/${priceList.id}/latest/versions`}
            additionalDetails={<StepperAdditionalInformation priceListId={priceListId} />}
        />
    );
}
  