import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createProductDynamics, fetchProductDynamicsById, getProductDynamic, isCreatingProductDynamics, isLoadingProductDynamics, isUpdatingProductDynamics,
    updateProductDynamics } from '../../reducers/productReducer';
import GenericStepper from '../common/widgets/genericStepper';
import GenericStep from '../../interfaces/common/genericStep';
import UrlConstants from '../../constants/UrlConstants';
import WorkflowUtils from '../../utils/workflowUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormType } from '../../constants/constants';
import ProductDynamics from '../../interfaces/output/productDynamics';
import AddProductDynamicsStep1 from './add/addProductDynamicsStep1';
import StepperAdditionalInformation from '../common/widgets/stepperAdditionalInformation';

interface IAddProductFormProps {
    productId?: number;
    productDynamicsId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add')
};

export default function AddProductForm(props: IAddProductFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { productId, productDynamicsId, steps, type } = props;
    const [productDynamics, setProductDynamics] = React.useState<ProductDynamics>({ productId } as ProductDynamics);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsProductDynamic = useSelector(getProductDynamic);
    const propsIsUpdatingProductDynamics = useSelector(isUpdatingProductDynamics);
    const propsIsLoadingProductDynamics = useSelector(isLoadingProductDynamics);
    const prevIsLoadingProductDynamics = WorkflowUtils.usePrevious<boolean>(propsIsLoadingProductDynamics);
    const prevIsUpdatingProductDynamics = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingProductDynamics);
    const propsIsCreatingProductDynamics = useSelector(isCreatingProductDynamics);
    const prevIsCreatingProductDynamics = WorkflowUtils.usePrevious<boolean>(propsIsCreatingProductDynamics);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if(prevIsUpdatingProductDynamics && !propsIsUpdatingProductDynamics || prevIsCreatingProductDynamics && !propsIsCreatingProductDynamics) {
                    setRedirectCondition(true);
                }
                break;
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsUpdatingProductDynamics, propsIsCreatingProductDynamics]);
    
    useEffect(() => {
        if(productDynamicsId) {
            dispatch(fetchProductDynamicsById(productDynamicsId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(!propsIsLoadingProductDynamics && prevIsLoadingProductDynamics) {
            setProductDynamics(propsProductDynamic);
        }

    }, [propsIsLoadingProductDynamics]);

    const onChange = (attribute: string, value: any) => {
        const newProduct = { ...productDynamics } as ProductDynamics;
        (newProduct as any)[attribute] = value;

        setProductDynamics(newProduct);
    };
    
    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddProductDynamicsStep1 onChange={onChange} productDynamics={productDynamics} />,
            onNext: () => {
                if(!productDynamics.id) {
                    dispatch(createProductDynamics(productDynamics));
                }
                else {
                    dispatch(updateProductDynamics(productDynamics));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={`/${UrlConstants.PRODUCTS}/${productId}/dynamics`}
            additionalDetails={<StepperAdditionalInformation productId={productId}/>}
        />
    );
}
  