import { Container } from '@mui/material';
import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import Pagination from '../../interfaces/common/pagination';
import Paging from '../../interfaces/common/paging';
import MainLayout from '../common/widgets/mainLayout';
import FontAwesomeIcon from '../../utils/FontAwesomeIcons';
import PageUtils from '../../utils/pageUtils';
import PriceList from '../../interfaces/output/priceList';
import PriceListVersion from '../../interfaces/output/priceListVersions';
import {
    fetchPriceListById, fetchPriceListVersions, getPriceList, getPriceListVersions, isCreatingPriceList, isDeleted, isLoadingPriceList,
    isLoadingPriceListVersions,
    isUpdatingPriceList,
    resetPriceList
} from '../../reducers/priceListReducer';
import { fetchProducts } from '../../reducers/productReducer';
import ProductFilters from '../../interfaces/output/productFilters';
import ViewPriceList from './viewPricelist';
import {
    deactivateAgreementVersionPricelists, demoteAgreementVersionPricelists, fetchAgreementVersionPricelists, getAgreementVersionPriceLists, isCreatingAgreementPricelist,
    isDeletingAgreementPricelist, isLoadingAgreementVersionPriceList,
    isLoadingAgreementVersionPriceLists,
    isUpdatingAgreementPricelist,
    promoteAgreementVersionPricelists
} from '../../reducers/agreementVersionPriceListReducer';
import AgreementVersionPriceListsFilters from '../../interfaces/filters/agreementVersionPriceListsFilters';
import AgreementVersionPricelist from '../../interfaces/output/agreementVersionPricelist';
import {
    deactivatePricelistVersionProduct, fetchPricelistVersionProducts, getPricelistVersionProducts, isCreatingPriceListVersionProduct,
    isLoadingPriceListVersionProduct, isUpdatingPriceListVersionProduct, isDeletingPriceListVersionProduct
} from '../../reducers/pricelistVersionProductReducer';
import PricelistVersionProduct from '../../interfaces/output/pricelistVersionProduct';
import PricelistVersionProductFilters from '../../interfaces/filters/pricelistVersionProductFilters';
import { fetchAgreementVersions } from '../../reducers/agreementsReducer';
import { ObjectType } from '../../constants/constants';
import ProductList from '../products/productList';
import AgreementList from '../agreements/agreementList';
import PricelistVersionList from './pricelistVersion/pricelistVersionList';
import LanguageUtils from '../../utils/LanguageUtils';
import NullableFormattedDate from '../common/nullableFormattedDate';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { fetchCalculatedPrice, getCalculatedPrice, isLoadingCalculatedPrice, resetCalculatedPrice } from '../../reducers/reportsReducer';
import CalculatedPriceFilters from '../../interfaces/filters/calculatedPriceFilters';
import CalculatedPriceList from '../reports/calculatedPrice/calculatedPriceList';
import CalculatedPrice from '../../interfaces/output/calculatedPrice';
import CalculatedPriceFilterComponent from '../reports/calculatedPrice/calculatedPriceFilterComponent';

interface IViewPriceListProps {
    classes: any;
    theme: Theme;
    fetchPriceListById: any;
    fetchPriceListVersions: any;
    fetchAgreementVersionPricelists: any;
    promoteAgreementVersionPricelists: any;
    demoteAgreementVersionPricelists: any;
    deactivateAgreementVersionPricelists: any;
    fetchPricelistVersionProducts: any;
    fetchAgreementVersions: any;
    deactivatePricelistVersionProduct: any;
    fetchProducts: any;
    priceList: PriceList;
    priceListVersions: Pagination<PriceListVersion>;
    pricelistAgreementVersions: Pagination<AgreementVersionPricelist>;
    pricelistVersionProducts: Pagination<PricelistVersionProduct>;
    match: any;
    history?: any;
    location?: any;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isLoading: boolean;
    isLoadingPriceList: boolean;
    isDeletingAgreementPricelist: boolean;
    isLoadingAgreementVersionPriceLists: boolean;
    isUpdatingAgreementPricelist: boolean;
    isCreatingAgreementPricelist: boolean;
    isLoadingPriceListVersionProduct: boolean;
    isUpdatingPriceListVersionProduct: boolean;
    isCreatingPriceListVersionProduct: boolean;
    isDeletingPriceListVersionProduct: boolean;
    isLoadingPriceListVersions: boolean;
    resetPriceList: () => void;
    calculatedPrice: Pagination<CalculatedPrice>;
    resetCalculatedPrice: () => void;
    fetchCalculatedPrice: any;
    isLoadingCalculatedPrice: boolean;
}

interface IViewPriceListState {
    priceList: PriceList;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverPagination: Paging;
    serverAgreementPagination: Paging;
    serverVersionPagination: Paging;
    sortModelProduct: GridSortModel;
    sortModelAgreement: GridSortModel;
    sortModelVersion: GridSortModel;
    productColumnVisibilityModel: GridColumnVisibilityModel;
    agreementColumnVisibilityModel: GridColumnVisibilityModel;
    priceListColumnVisibilityModel: GridColumnVisibilityModel;
    filtersCalculatedPrice: CalculatedPriceFilters;
    pagingCalculatedPrice: Paging;
    sortModelCalculatedPrice: GridSortModel;
    columnVisibilityModelCalculatedPrice: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchPriceListById: (id: number, priceListVersionId?: number) => dispatch(fetchPriceListById({
        id,
        priceListVersionId
    })),
    fetchPriceListVersions: (id: number, paging: Paging) => dispatch(fetchPriceListVersions({
        id,
        paging
    })),
    fetchProducts: (paging: Paging, filters: ProductFilters) => dispatch(fetchProducts({
        paging,
        filters
    })),
    fetchAgreementVersionPricelists: (paging: Paging, filters: AgreementVersionPriceListsFilters) => dispatch(fetchAgreementVersionPricelists({
        paging,
        filters
    })),
    fetchPricelistVersionProducts: (paging: Paging, filters: PricelistVersionProductFilters) => dispatch(fetchPricelistVersionProducts({
        paging,
        filters
    })),
    promoteAgreementVersionPricelists: (id: number) => dispatch(promoteAgreementVersionPricelists(id)),
    demoteAgreementVersionPricelists: (id: number) => dispatch(demoteAgreementVersionPricelists(id)),
    deactivateAgreementVersionPricelists: (id: number) => dispatch(deactivateAgreementVersionPricelists(id)),
    deactivatePricelistVersionProduct: (id: number) => dispatch(deactivatePricelistVersionProduct(id)),
    resetPriceList: () => dispatch(resetPriceList()),
    fetchAgreementVersions: (id: number, paging: Paging) => dispatch(fetchAgreementVersions({
        id,
        paging
    })),
    fetchCalculatedPrice: (paging: Paging, filters: CalculatedPriceFilters) => dispatch(fetchCalculatedPrice({
        paging,
        filters
    })),
    resetCalculatedPrice: () => dispatch(resetCalculatedPrice())
});

const mapStoreToProps = (store: RootState) => {
    return {
        priceList: getPriceList(store),
        pricelistAgreementVersions: getAgreementVersionPriceLists(store),
        pricelistVersionProducts: getPricelistVersionProducts(store),
        priceListVersions: getPriceListVersions(store),
        calculatedPrice: getCalculatedPrice(store),
        isUpdating: isUpdatingPriceList(store),
        isDeleting: isDeleted(store),
        isCreating: isCreatingPriceList(store),
        isUpdatingAgreementPricelist: isUpdatingAgreementPricelist(store),
        isDeletingAgreementPricelist: isDeletingAgreementPricelist(store),
        isLoadingAgreementVersionPriceLists: isLoadingAgreementVersionPriceLists(store),
        isCreatingAgreementPricelist: isCreatingAgreementPricelist(store),
        isLoadingPriceList: isLoadingPriceList(store),
        isLoadingPriceListVersionProduct: isLoadingPriceListVersionProduct(store),
        isUpdatingPriceListVersionProduct: isUpdatingPriceListVersionProduct(store),
        isCreatingPriceListVersionProduct: isCreatingPriceListVersionProduct(store),
        isDeletingPriceListVersionProduct: isDeletingPriceListVersionProduct(store),
        isLoadingPriceListVersions: isLoadingPriceListVersions(store),
        isLoadingCalculatedPrice: isLoadingCalculatedPrice(store),
        isLoading: isUpdatingPriceList(store) || isDeleted(store) || isCreatingPriceList(store) || isLoadingAgreementVersionPriceList(store) || isLoadingCalculatedPrice(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    },
    extraSpace: {
        marginRight: theme.spacing(1)
    },
    values: {
        color: theme.palette.common.black
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Deactivate'),
    promote: LanguageUtils.createMessage('Promote'),
    demote: LanguageUtils.createMessage('Demote'),
    changeStatus: LanguageUtils.createMessage('Change version status'),
    properties: LanguageUtils.createMessage('Properties'),
    versions: LanguageUtils.createMessage('Versions'),
    products: LanguageUtils.createMessage('Products'),
    agreements: LanguageUtils.createMessage('Agreements'),
    printOut: LanguageUtils.createMessage('Print out'),
    subname: LanguageUtils.createMessage('PRICE_LISTS'),
    clone: LanguageUtils.createMessage('CLONE_CURRENT_VERSION')
};

class PriceListDetails extends Component<IViewPriceListProps, IViewPriceListState> {
    calculatedPriceListRef: any;

    constructor(props: IViewPriceListProps) {
        super(props);

        this.state = {
            priceList: {} as PriceList,
            menuItems: this.configureDrawer(props),
            serverPagination: PageUtils.getDefaultPaging(),
            serverAgreementPagination: PageUtils.getDefaultPaging(),
            serverVersionPagination: PageUtils.getDefaultPaging(),
            selectedRow: undefined,
            sortModelAgreement: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelVersion: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelProduct: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            productColumnVisibilityModel: {
            },
            agreementColumnVisibilityModel: {
                priceList_revenueLastYear: false,
                priceList_revenueThisYear: false,
                agreementVersion_validFromDate: false,
                agreementVersion_validToDate: false
            },
            priceListColumnVisibilityModel: {
                priceList_name: false,
                basePrice: false,
                onHold: false,
                factorSetDangerousGoods: false,
                factorSetCancellation: false

            },
            filtersCalculatedPrice: {} as CalculatedPriceFilters,
            pagingCalculatedPrice: PageUtils.getDefaultPaging([{
                field: 'priceListName',
                sort: 'desc'
            } as GridSortItem]),
            sortModelCalculatedPrice: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModelCalculatedPrice: {}
        };
    }

    componentDidMount() {
        const { fetchPriceListById } = this.props;
        const { priceListId, priceListVersionId } = this.props.match.params;

        const versionId = priceListVersionId === 'latest' ? undefined : priceListVersionId;

        fetchPriceListById(priceListId, versionId);
    }

    componentDidUpdate(prevProps: IViewPriceListProps) {
        const { priceList, fetchPriceListById, isUpdatingAgreementPricelist, isDeletingAgreementPricelist, isCreatingAgreementPricelist,
            isLoadingPriceList, isUpdatingPriceListVersionProduct, isCreatingPriceListVersionProduct, isDeletingPriceListVersionProduct } = this.props;
        const { priceListId, priceListVersionId } = this.props.match.params;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);

        if(priceList !== prevProps.priceList) {
            this.setState({ priceList });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                menuItems: this.configureDrawer(this.props),
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));

            if(prevProps.match.params.priceListVersionId !== priceListVersionId) {
                const versionId = priceListVersionId === 'latest' ? undefined : priceListVersionId;
                fetchPriceListById(priceListId, versionId);
            }
        }

        if(selectedMenuItem && (!isLoadingPriceList && prevProps.isLoadingPriceList) ||
            !isUpdatingAgreementPricelist && prevProps.isUpdatingAgreementPricelist ||
            !isDeletingAgreementPricelist && prevProps.isDeletingAgreementPricelist ||
            !isCreatingAgreementPricelist && prevProps.isCreatingAgreementPricelist ||
            !isUpdatingPriceListVersionProduct && prevProps.isUpdatingPriceListVersionProduct ||
            !isDeletingPriceListVersionProduct && prevProps.isDeletingPriceListVersionProduct ||
            !isCreatingPriceListVersionProduct && prevProps.isCreatingPriceListVersionProduct) {
            this.loadData(selectedMenuItem);
        }
    }

    componentWillUnmount() {
        const { resetPriceList } = this.props;

        resetPriceList();
    }

    isHiddenCategory = (): boolean => {
        const { priceList } = this.state;

        return priceList.version?.id ? false : true;
    }

    configureDrawer = (props: IViewPriceListProps): ImmutableList<IMenuItem> => {
        const baseUrl = `/${UrlConstants.PRICE_LISTS}/${props.match.params.priceListId}/${props.match.params.priceListVersionId}`;

        return ImmutableList<IMenuItem>([{
            id: 1,
            name: messages.properties,
            icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
            url: `${baseUrl}/properties`,
            additionalMargin: true,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/properties`, props.location.pathname)
        },
        {
            id: 2,
            name: messages.agreements,
            icon: <FontAwesomeIcon icon="handshake" size="1x" transform="grow-4" />,
            url: `${baseUrl}/agreements`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/agreements`, props.location.pathname)
        },
        {
            id: 3,
            name: messages.versions,
            icon: <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-4" />,
            additionalMargin: true,
            url: `${baseUrl}/versions`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/versions`, props.location.pathname)
        },
        {
            id: 4,
            name: messages.products,
            icon: <FontAwesomeIcon icon="cubes" size="1x" transform="grow-4" />,
            url: `${baseUrl}/products`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/products`, props.location.pathname),
            isHidden: this.isHiddenCategory
        },
        {
            id: 5,
            name: messages.printOut,
            icon: <FontAwesomeIcon icon="print" size="1x" transform="grow-4" />,
            url: `${baseUrl}/${UrlConstants.REPORTS_CALCULATED_PRICE}`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/${UrlConstants.REPORTS_CALCULATED_PRICE}`, props.location.pathname),
            isHidden: this.isHiddenCategory
        }
        ]);
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { priceListId } = this.props.match.params;
        const { fetchPriceListVersions, fetchAgreementVersionPricelists, fetchPricelistVersionProducts, priceList, fetchCalculatedPrice } = this.props;

        const paging = PageUtils.getDefaultPaging();
        this.setState({
            serverPagination: paging,
            serverAgreementPagination: paging,
            serverVersionPagination: paging,
            pagingCalculatedPrice: paging
        });

        if(selectedMenuItem?.id === 4 && priceList.version?.id) {
            fetchPricelistVersionProducts(paging, { pricelistVersionId: priceList.version?.id });
        }

        if(selectedMenuItem?.id === 2) {
            fetchAgreementVersionPricelists(paging, { pricelistId: priceListId });
        }

        if(selectedMenuItem?.id === 3) {
            fetchPriceListVersions(priceListId, paging);
        }

        if(selectedMenuItem?.id === 5) {
            const { priceListVersionId } = this.props.match.params;
            const versionId = (priceListVersionId !== 'latest' && priceListVersionId !== undefined)
                ? priceListVersionId : priceList?.currentVersion?.id !== undefined
                    ? priceList?.currentVersion?.id : undefined;
            const filters = {
                pricelistVersionId: versionId
            } as CalculatedPriceFilters;

            fetchCalculatedPrice(paging, filters);
        }
    }

    getSelectedMenuItem = (state: IViewPriceListState) => state.menuItems.find(item => item.isSelected);

    promotePricelistAgreement = () => {
        const { promoteAgreementVersionPricelists } = this.props;
        const { selectedRow } = this.state;

        promoteAgreementVersionPricelists(selectedRow);
    }

    demotePricelistAgreement = () => {
        const { selectedRow } = this.state;
        const { demoteAgreementVersionPricelists } = this.props;

        demoteAgreementVersionPricelists(selectedRow);
    }

    deactivatePricelistAgreement = () => {
        const { selectedRow } = this.state;
        const { deactivateAgreementVersionPricelists } = this.props;

        deactivateAgreementVersionPricelists(selectedRow);
    }

    promoteAgreementVersionPricelist = () => {
        const { promoteAgreementVersionPricelists } = this.props;
        const { selectedRow } = this.state;

        promoteAgreementVersionPricelists(selectedRow);
    }

    demoteAgreementVersionPricelist = () => {
        const { selectedRow } = this.state;
        const { demoteAgreementVersionPricelists } = this.props;

        demoteAgreementVersionPricelists(selectedRow);
    }

    deactivateAgreementVersionPricelist = () => {
        const { selectedRow } = this.state;
        const { deactivateAgreementVersionPricelists } = this.props;

        deactivateAgreementVersionPricelists(selectedRow);
    }

    deactivatePricelistVersionProduct = () => {
        const { selectedRow } = this.state;
        const { deactivatePricelistVersionProduct } = this.props;

        deactivatePricelistVersionProduct(selectedRow);
    }

    handleMenuItems = () => {
        const { priceList, menuItems, selectedRow } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { priceListId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.PRICE_LISTS}/${priceListId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        href: `${UrlConstants.PRICE_LISTS}/${priceListId}/version/${priceList.version?.id}/clone`,
                        icon: 'clone',
                        text: messages.clone,
                        disabled: !priceList.version?.id
                    }
                ];
            }

            case 4: { //products
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.PRICE_LISTS}/${priceListId}/version/${priceList.version?.id}/add/pricelistversionproduct`,
                            icon: 'plus',
                            text: messages.add,
                            disabled: !priceList.version?.id
                        }
                    ];
                }

                return [
                    {
                        href: `${UrlConstants.PRICE_LISTS}/${UrlConstants.PRICE_LIST_VERSION_PRODUCTS}/${selectedRow}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        onClick: this.deactivatePricelistVersionProduct,
                        icon: 'ban',
                        text: messages.delete
                    }
                ];
            }

            case 2: { //agreements
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.PRICE_LISTS}/${priceListId}/add/agreementversionpricelist`,
                            icon: 'plus',
                            text: messages.add
                        }
                    ];
                }

                return [{
                    href: `${UrlConstants.PRICE_LISTS}/${UrlConstants.AGREEMENT_VERSION_PRICE_LIST}/${selectedRow}/edit`,
                    icon: 'edit',
                    text: messages.edit
                },
                {
                    icon: 'angle-up',
                    text: messages.promote,
                    onClick: this.promoteAgreementVersionPricelist
                }, {
                    icon: 'angle-down',
                    text: messages.demote,
                    onClick: this.demoteAgreementVersionPricelist
                }, {
                    icon: 'ban',
                    text: messages.delete,
                    onClick: this.deactivateAgreementVersionPricelist
                }];
            }

            case 3: { //priceList versions
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.PRICE_LISTS}/${priceListId}/add/version`,
                            icon: 'plus',
                            text: messages.add
                        }
                    ];
                }

                return [{
                    href: `${UrlConstants.PRICE_LISTS}/${priceListId}/version/${selectedRow}/edit`,
                    icon: 'edit',
                    text: messages.edit
                },
                {
                    href: `${UrlConstants.PRICE_LISTS}/${priceListId}/version/${selectedRow}/status`,
                    icon: 'retweet',
                    text: messages.changeStatus
                }];
            }

            default: {
                return [];
            }
        }
    }

    _onSortProductChange = (newModel: GridSortModel) => {
        const { fetchPricelistVersionProducts } = this.props;
        const { serverPagination, sortModelProduct, priceList } = this.state;

        if(JSON.stringify(sortModelProduct) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPagination,
                sort: newModel
            };
            this.setState({
                sortModelProduct: newModel,
                serverPagination: newPagination
            });

            fetchPricelistVersionProducts(newPagination, { pricelistVersionId: priceList.version?.id });
        }
    }
    _onSortAgreementChange = (newModel: GridSortModel) => {
        const { fetchAgreementVersionPricelists } = this.props;
        const { serverAgreementPagination, sortModelAgreement, priceList } = this.state;

        if(JSON.stringify(sortModelAgreement) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverAgreementPagination,
                sort: newModel
            };
            this.setState({
                sortModelAgreement: newModel,
                serverAgreementPagination: newPagination
            });

            fetchAgreementVersionPricelists(newPagination, { pricelistId: priceList.id });

        }
    }
    _onSortVersionChange = (newModel: GridSortModel) => {
        const { fetchPriceListVersions } = this.props;
        const { serverVersionPagination, sortModelVersion, priceList } = this.state;

        if(JSON.stringify(sortModelVersion) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverVersionPagination,
                sort: newModel
            };
            this.setState({
                sortModelVersion: newModel,
                serverVersionPagination: newPagination
            });

            fetchPriceListVersions(priceList.id, newPagination);
        }
    }

    _applyFiltersCalculatedPrice = () => {
        const { fetchCalculatedPrice } = this.props;
        const { pagingCalculatedPrice, filtersCalculatedPrice, priceList } = this.state;
        const { priceListVersionId } = this.props.match.params;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCalculatedPrice,
            page: newPaging.page
        };

        const versionId = (priceListVersionId !== 'latest' && priceListVersionId !== undefined)
            ? priceListVersionId : priceList?.currentVersion?.id !== undefined
                ? priceList?.currentVersion?.id : undefined;
        const filters = {
            ...filtersCalculatedPrice,
            pricelistVersionId: versionId
        } as CalculatedPriceFilters;

        this.setState({
            pagingCalculatedPrice: newPagination,
            filtersCalculatedPrice: filters
        });

        fetchCalculatedPrice(newPagination, filters);

        if(this.calculatedPriceListRef !== null && this.calculatedPriceListRef !== undefined &&
            this.calculatedPriceListRef.current !== null && this.calculatedPriceListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.calculatedPriceListRef.current.resetDataGridPage();
        }
    }

    _onChangeCalculatedPrice = (attribute: string, value: any) => {
        const { filtersCalculatedPrice, pagingCalculatedPrice } = this.state;

        const newFilter = { ...filtersCalculatedPrice } as CalculatedPriceFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCalculatedPrice,
            page: newPaging.page
        };

        this.setState({
            filtersCalculatedPrice: newFilter,
            pagingCalculatedPrice: newPagination
        });
    }

    _setDefaultStateCalculatedPrice = () => {
        const { fetchCalculatedPrice } = this.props;
        const { priceListVersionId } = this.props.match.params;
        const { pagingCalculatedPrice, priceList } = this.state;

        const versionId = (priceListVersionId !== 'latest' && priceListVersionId !== undefined)
            ? priceListVersionId : priceList?.currentVersion?.id !== undefined
                ? priceList?.currentVersion?.id : undefined;
        const newFilters = {
            pricelistVersionId: versionId
        };

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCalculatedPrice,
            page: newPaging.page
        };

        this.setState({
            pagingCalculatedPrice: newPagination,
            filtersCalculatedPrice: newFilters
        });

        fetchCalculatedPrice(newPagination, newFilters);

        if(this.calculatedPriceListRef !== null && this.calculatedPriceListRef !== undefined &&
            this.calculatedPriceListRef.current !== null && this.calculatedPriceListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.calculatedPriceListRef.current.resetDataGridPage();
        }
    }

    _onSortChangeCalculatedPrice = (newModel: GridSortModel) => {
        const { fetchCalculatedPrice } = this.props;
        const { sortModelCalculatedPrice, filtersCalculatedPrice, pagingCalculatedPrice, priceList } = this.state;
        const { priceListVersionId } = this.props.match.params;

        if(JSON.stringify(sortModelCalculatedPrice) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...pagingCalculatedPrice,
                sort: newModel
            };

            const versionId = (priceListVersionId !== 'latest' && priceListVersionId !== undefined)
                ? priceListVersionId : priceList?.currentVersion?.id !== undefined
                    ? priceList?.currentVersion?.id : undefined;
            const filters = {
                ...filtersCalculatedPrice,
                pricelistVersionId: versionId
            } as CalculatedPriceFilters;

            this.setState({
                sortModelCalculatedPrice: newModel,
                pagingCalculatedPrice: newPaging,
                filtersCalculatedPrice: filters
            });

            fetchCalculatedPrice(newPaging, filters);

        }
    }

    render() {
        const { classes, isLoadingPriceList, priceListVersions, pricelistAgreementVersions, isLoadingAgreementVersionPriceLists, pricelistVersionProducts, isLoadingPriceListVersionProduct,
            isDeletingPriceListVersionProduct, isDeletingAgreementPricelist, fetchPriceListVersions, fetchAgreementVersionPricelists, fetchPricelistVersionProducts, isLoadingPriceListVersions,
            isLoadingCalculatedPrice, fetchCalculatedPrice, calculatedPrice }
            = this.props;
        const { priceList, menuItems, serverPagination, serverAgreementPagination, serverVersionPagination,
            productColumnVisibilityModel, agreementColumnVisibilityModel, priceListColumnVisibilityModel,
            filtersCalculatedPrice, pagingCalculatedPrice, columnVisibilityModelCalculatedPrice } = this.state;
        const { priceListVersionId } = this.props.match.params;

        return (
            <MainLayout
                actions={this.handleMenuItems()}
                isLoading={isLoadingPriceList}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.PriceList}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: priceList.name,
                    icon: <FontAwesomeIcon icon="tag" size="1x" />
                } as IMenuItem}
                additionalElement={
                    <strong>
                        {!!priceList?.version?.versionNumber &&
                            (<>
                                <span className={classes.extraSpace}> <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-3" className={classes.extraSpace} />
                                    <span className={classes.values}> {priceList?.version?.versionNumber ?? '-'} </span>
                                </span>
                                <span>
                                    <FontAwesomeIcon icon="calendar-alt" size="1x" transform="grow-3" className={classes.extraSpace} />
                                    {priceList?.version?.validFromDate || priceList?.version?.validToDate ? (
                                        <span className={classes.values}>
                                            <NullableFormattedDate value={priceList?.version?.validFromDate} /> -
                                            {priceList?.version?.validToDate &&
                                                <> <NullableFormattedDate value={priceList?.version?.validToDate} /></>}
                                        </span>
                                    )
                                        : '-'} </span>
                            </>)
                        }
                    </strong>
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.PRICE_LISTS}/:priceListId/:priceListVersionId/properties`}>
                            <ViewPriceList priceList={priceList} />
                        </Route>
                        <Route path={`/${UrlConstants.PRICE_LISTS}/:priceListId/:priceListVersionId/products`}>
                            <ProductList
                                prefix={'product_'}
                                isLoading={isLoadingPriceListVersionProduct || isDeletingPriceListVersionProduct}
                                disableLinks
                                isInPriceListDetails
                                rows={pricelistVersionProducts} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                applyRowFn={(cellValues: any) => cellValues.row.product}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchPricelistVersionProducts(newPage, { pricelistVersionId: priceList.version?.id });
                                    this.setState({ serverPagination: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortProductChange(sortModel)}
                                columnVisibilityModel={productColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                    this.setState({
                                        productColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.PRICE_LISTS}/:priceListId/:priceListVersionId/agreements`}>
                            <AgreementList
                                isLoading={isLoadingAgreementVersionPriceLists || isDeletingAgreementPricelist} rows={pricelistAgreementVersions}
                                prefix={'pricelist_'}
                                secondPrefix={'agreementVersion_'}
                                isFromPriceList={true}
                                checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                applyRowFn={(cellValues: any) => cellValues.row.agreement}
                                hiddenColumns={['revenueLastYear', 'revenueThisYear', 'validFromDate', 'validToDate']}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverAgreementPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchAgreementVersionPricelists(newPage, { pricelistId: priceList.id });
                                    this.setState({ serverAgreementPagination: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortAgreementChange(sortModel)}
                                columnVisibilityModel={agreementColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                    this.setState({
                                        agreementColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.PRICE_LISTS}/:priceListId/:priceListVersionId/versions`}>
                            <PricelistVersionList rows={priceListVersions} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                isLoading={isLoadingPriceListVersions}
                                secondPrefix={'pricelist_'}
                                hiddenColumns={['basePrice', 'onHold', 'name']}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverVersionPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchPriceListVersions(priceList.id, newPage);
                                    this.setState({ serverVersionPagination: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortVersionChange(sortModel)}
                                columnVisibilityModel={priceListColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                    this.setState({
                                        priceListColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.PRICE_LISTS}/:priceListId/:priceListVersionId/${UrlConstants.REPORTS_CALCULATED_PRICE}`}>
                            <CalculatedPriceFilterComponent
                                applyFilters={this._applyFiltersCalculatedPrice} setDefaultState={this._setDefaultStateCalculatedPrice}
                                filters={filtersCalculatedPrice} onChange={this._onChangeCalculatedPrice} />
                            <CalculatedPriceList rows={calculatedPrice} isLoading={isLoadingCalculatedPrice}
                                ref={this.calculatedPriceListRef}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...pagingCalculatedPrice,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    const versionId = (priceListVersionId !== 'latest' && priceListVersionId !== undefined) ? priceListVersionId
                                        : priceList?.currentVersion?.id !== undefined ? priceList?.currentVersion?.id : undefined;
                                    const newFilters = {
                                        ...filtersCalculatedPrice,
                                        pricelistVersionId: versionId
                                    } as CalculatedPriceFilters;

                                    fetchCalculatedPrice(newPage, newFilters);
                                    this.setState({
                                        pagingCalculatedPrice: newPage,
                                        filtersCalculatedPrice: newFilters
                                    });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeCalculatedPrice(sortModel)}
                                columnVisibilityModel={columnVisibilityModelCalculatedPrice}
                                onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                    this.setState({
                                        columnVisibilityModelCalculatedPrice: newModel
                                    })
                                }
                            />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(PriceListDetails));