import React, { useEffect } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import GenericStepper from '../common/widgets/genericStepper';
import { useDispatch, useSelector } from 'react-redux';
import { FormType } from '../../constants/constants';
import { createCustomerAgreementsBulk, fetchCustomerAgreementById, getCustomerAgreement, isCreatingBulk, isLoadingCustomerAgreement, isUpdatingCustomerAgreement,
    updateCustomerAgreement } from '../../reducers/customerAgreementReducer';
import WorkflowUtils from '../../utils/workflowUtils';
import CustomerAgreement from '../../interfaces/output/customerAgreement';
import GenericStep from '../../interfaces/common/genericStep';
import UrlConstants from '../../constants/UrlConstants';
import { List } from 'immutable';
import AddCustomerAgreementStep1 from './addCustomerAgreementStep1';
import AddCustomerAgreementStep2 from './addCustomerAgreementStep2';
import AddCustomerAgreementStep3 from './addCustomerAgreementStep3';
import CustomerAgreementValidation from './validation/customerAgreementPriorityAndValidityValidation';
import CustomerSelectionValidation from './validation/customerSelectionValidation';
import AgreementSelectionValidation from './validation/agreementSelectionValidation';
import StepperAdditionlInformation from '../common/widgets/stepperAdditionalInformation';

interface IAddCustomerAgreementProps {
    customerId?: number;
    agreementId?: number;
    customerAgreementId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit customer agreement'),
    add: LanguageUtils.createMessage('Add customer agreement')
};

export default function AddCustomerAgreementForm(props: IAddCustomerAgreementProps): JSX.Element {
    const dispatch = useDispatch();
    const { customerId, agreementId, customerAgreementId, steps, type } = props;
    const [customerAgreement, setCustomerAgreement] = React.useState<CustomerAgreement>({} as CustomerAgreement);
    const [selectedIds, setSelectedIds] = React.useState<List<number>>(List());
    const propsCustomerAgreement = useSelector(getCustomerAgreement);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsIsCreatingCustomerAgreementBulk = useSelector(isCreatingBulk);
    const prevIsCreatingCustomerAgreementBulk = WorkflowUtils.usePrevious<boolean>(propsIsCreatingCustomerAgreementBulk);
    const propsIsUpdatingCustomerAgreement = useSelector(isUpdatingCustomerAgreement);
    const prevIsUpdatingCustomerAgreement = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingCustomerAgreement);
    const propsIsLoadingCustomerAgreement = useSelector(isLoadingCustomerAgreement);
    const prevIsLoadingCustomerAgreement = WorkflowUtils.usePrevious<boolean>(propsIsLoadingCustomerAgreement);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 3:
                if(prevIsUpdatingCustomerAgreement === true && !propsIsUpdatingCustomerAgreement || prevIsCreatingCustomerAgreementBulk === true && !propsIsCreatingCustomerAgreementBulk) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsUpdatingCustomerAgreement, propsIsCreatingCustomerAgreementBulk]);
    
    useEffect(() => {
        if(customerAgreementId) {
            dispatch(fetchCustomerAgreementById(customerAgreementId));
        }
    }, [dispatch]);
    
    // useEffect(() => {
    //     if(propsCustomerAgreement.id) {
    //         setCustomerAgreement(propsCustomerAgreement);
    //     }

    // }, [propsIsUpdatingCustomerAgreement, propsCustomerAgreement.id]);

    useEffect(() => {
        if(!propsIsLoadingCustomerAgreement && prevIsLoadingCustomerAgreement) {
            setCustomerAgreement(propsCustomerAgreement);
        }
    }, [propsIsLoadingCustomerAgreement]);

    const onChange = (attribute: string, value: any) => {
        const newCustomerAgreement = { ...customerAgreement } as CustomerAgreement;
        (newCustomerAgreement as any)[attribute] = value;

        setCustomerAgreement(newCustomerAgreement);
    };

    const onChangeList = (values: number[]) => {
        // let newList = selectedIds;
        // if(!newList.includes(value)) {
        //     newList = newList.push(value);
        // }
        // else {
        //     newList = newList.delete(value);
        // }
        const list = List(values);
        setSelectedIds(list);
    };
       
    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            name: 'Select agreement from list',
            xl: true,
            validationFn: () => AgreementSelectionValidation.validateCustomerAgreementForm(selectedIds),
            content: <AddCustomerAgreementStep1 onChange={onChangeList} customerAgreements={selectedIds} />
        } as GenericStep,
        {
            id: 2,
            name: 'Select customer from list',
            xl: true,
            validationFn: () => CustomerSelectionValidation.validateCustomerAgreementForm(selectedIds),
            content: <AddCustomerAgreementStep2 onChange={onChangeList} customerAgreements={selectedIds} />
        } as GenericStep,
        {
            id: 3,
            name: 'Enter validity and priority',
            content: <AddCustomerAgreementStep3 onChange={onChange} customerAgreement={customerAgreement} />,
            validationFn: () => CustomerAgreementValidation.validateCustomerAgreementForm(customerAgreement),
            onNext: () => {
                if(!customerAgreement.id) {
                    const newCustomerAgreement = { ...customerAgreement } as CustomerAgreement;
                    const customerAgreementsBulk = selectedIds.map(selectedId => {
                        let customerId = props.customerId, agreementId = props.agreementId;
                        if(type === FormType.AddCustomerAgreementFromAgreement) {
                            customerId = selectedId;
                        }
                        else {
                            agreementId = selectedId;
                        }
                        
                        return { ...newCustomerAgreement,
                            customer: { id: customerId },
                            agreement: { id: agreementId } } as CustomerAgreement;
                    });
                    dispatch(createCustomerAgreementsBulk({ customerAgreements: customerAgreementsBulk }));
                }
                else {
                    dispatch(updateCustomerAgreement(customerAgreement));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.AddCustomerAgreementFromAgreement:
            case FormType.AddCustomerAgreementFromCustomer:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };
    
    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={customerId ? `/${UrlConstants.CUSTOMERS}/${customerId}/agreements` :
                customerAgreement.agreement?.version?.id ? `/${UrlConstants.AGREEMENTS}/${agreementId}/${customerAgreement.agreement?.version?.id}` :
                    `/${UrlConstants.AGREEMENTS}/${agreementId}/latest/customers`}
            additionalDetails={<StepperAdditionlInformation agreementId={agreementId} customerId={customerId} />}
        />
    );
}