import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../common/widgets/genericStepper';
import GenericStep from '../../../interfaces/common/genericStep';
import Product from '../../../interfaces/output/agreement';
import UrlConstants from '../../../constants/UrlConstants';
import WorkflowUtils from '../../../utils/workflowUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormType } from '../../../constants/constants';
import AddAgreementVersionDiscountStep1 from './addAgreementVersionDiscountStep1';
import { isCreatingDiscount, createAgreementVersionTransitDiscount } from '../../../reducers/agreementVersionReducer';
import TransitDiscount from '../../../interfaces/output/transitDiscount';
import { fetchTransitDiscountById, getAgreementVersionTransitDiscount, isLoadingAgreementVersionTransitDiscount, isUpdatingAgreementVersionTransitDiscount,
    updateTransitDiscount } from '../../../reducers/transitDiscountReducer';
import StepperAdditionalInformation from '../../common/widgets/stepperAdditionalInformation';

interface IAddAgreementVersionDiscountFormProps {
    agreementId?: number;
    agreementVersionId?: number;
    discountId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit transit discount'),
    add: LanguageUtils.createMessage('Add transit discount')
};

export default function AddAgreementVersionDiscountForm(props: IAddAgreementVersionDiscountFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { agreementVersionId, discountId, steps, type, agreementId } = props;
    const [agreementDiscount, setAgreementDiscount] = React.useState<TransitDiscount>({ agreementVersionId } as TransitDiscount);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsAgreementVersionDiscount = useSelector(getAgreementVersionTransitDiscount);
    const propsIsUpdatingAgreementDiscount = useSelector(isUpdatingAgreementVersionTransitDiscount);
    const propsIsLoadingAgreementDiscount = useSelector(isLoadingAgreementVersionTransitDiscount);
    const prevIsLoadingAgreementDiscount = WorkflowUtils.usePrevious<boolean>(propsIsLoadingAgreementDiscount);
    const prevIsUpdatingAgreementDiscount = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingAgreementDiscount);
    const propsIsCreatingAgreementDiscount = useSelector(isCreatingDiscount);
    const prevIsCreatingAgreementDiscount = WorkflowUtils.usePrevious<boolean>(propsIsCreatingAgreementDiscount);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if(prevIsUpdatingAgreementDiscount && !propsIsUpdatingAgreementDiscount || prevIsCreatingAgreementDiscount && !propsIsCreatingAgreementDiscount) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsUpdatingAgreementDiscount, propsIsCreatingAgreementDiscount]);
    
    useEffect(() => {
        if(discountId) {
            dispatch(fetchTransitDiscountById(discountId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(!propsIsLoadingAgreementDiscount && prevIsLoadingAgreementDiscount) {
            setAgreementDiscount(propsAgreementVersionDiscount);
        }

    }, [propsIsLoadingAgreementDiscount]);

    const onChange = (attribute: string, value: any) => {
        const newProduct = { ...agreementDiscount } as Product;
        (newProduct as any)[attribute] = value;

        setAgreementDiscount(newProduct);
    };
    
    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddAgreementVersionDiscountStep1 onChange={onChange} agreementDiscount={agreementDiscount} />,
            onNext: () => {
                if(!agreementDiscount.id) {
                    dispatch(createAgreementVersionTransitDiscount(agreementDiscount));
                }
                else {
                    dispatch(updateTransitDiscount(agreementDiscount));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = (): string => {
        switch (type) {
            case FormType.Add:
                return `/${UrlConstants.AGREEMENTS}/${agreementId}/${agreementVersionId}/discounts`;

            case FormType.Edit:
                return `/${UrlConstants.AGREEMENT_VERSION_TRANSIT_DISCOUNT}/${propsAgreementVersionDiscount.id}`;

            default:
                throw new Error('Redirect to required');
        }
    };
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
            additionalDetails={<StepperAdditionalInformation agreementId={agreementId} agreementVersionId={agreementVersionId} />}
        />
    );
}
  